import { styled } from 'styled-components';

export const StyledWinnerTitle = styled.div`
  font-family: Atma;
  text-align: center;
  font-size: 88px;
  font-weight: 700;
  color: #d1aa23;
`;

export const StyledGiftContainer = styled.img`
  padding: 10px;
  max-width: 80%;
  max-height: 80%;
`;

import { Fragment } from 'react';

import { TGameData, TParticipant } from '../../types';

import AdventCalendarGame from './AdventCalendarGame';
import ChristmasFortuneWheelGame from './ChristmasFortuneWheelGame';
import ClassicFortuneWheel from './ClassicWheelGame';

type TGameProps = {
  game: TGameData;
  participant: TParticipant;
  userToken: string;
};

export const classicWheelId = 'wheel-fortune-classic';
export const estivalWheelId = 'wheel-fortune-estival';
export const fortuneWheelId = 'wheel-fortune-christmas';
export const adventCalendarId = 'advent-calendar';
export const sirhaWheelId = 'wheel-fortune-sirha';

const index = ({ game, participant, userToken }: TGameProps) => {
  return (
    <Fragment>
      {game.animationType === classicWheelId && (
        <ClassicFortuneWheel game={game} participant={participant} userToken={userToken} footerColor="white" />
      )}
      {game.animationType === estivalWheelId && (
        <ClassicFortuneWheel
          game={game}
          participant={participant}
          userToken={userToken}
          imagesPath="assets/estivalWheel"
        />
      )}
      {game.animationType === sirhaWheelId && (
        <ClassicFortuneWheel
          game={game}
          participant={participant}
          userToken={userToken}
          imagesPath="assets/sirhaWheel"
          backgroundSize="100vw auto"
          backgroundMobileSize="100vw auto"
          hasMobileBackground
          backgroundRepeat={false}
        />
      )}
      {game.animationType === fortuneWheelId && (
        <ChristmasFortuneWheelGame game={game} participant={participant} userToken={userToken} />
      )}
      {game.animationType === adventCalendarId && (
        <AdventCalendarGame game={game} participant={participant} userToken={userToken} />
      )}
    </Fragment>
  );
};

export default index;
